$Green:#006241;
$Goldenrod:#F0CC7C;
$BrownSugar:#E7AC79;
$BrownishGrey:#7A7A66;
$BrightGrey:#46454C;
$Black:#000000;
$Linen:#F5F0E7;
$LavenderGrey:#C9C9C9;
$VistaWhite:#FBF8F4;
$white:#FFFFFF;
$Platinum:#E1E1E1;
$DarkGrey:#343A40;
$Vanilla:#C7BFB0;
$RoseWhite:#FBF8F3;
$MidnightBlue:#0B0732;

