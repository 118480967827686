@import "../../assets/scss/variable.scss";
.navbar-header {
    .half-drop-down {
        button {
            font-size: 15px;
            &::after {
                border: 0;
                background-image: url("../../assets/images/down-arrow.svg");
                width: 12px;
                height: 7px;
                background-position: center;
                background-size: cover;
                margin-left: 12px;
            }
        }
    }
    .navbar-icon {
        &:focus {
            background-color: transparent;
        }
    }
    .done-btn{
        border-radius: 20px;
        font-size: 15px;
        padding: 8px 31px;
    }
    .wallet-dropdown{
        min-width: 138px;
        transform: translate(-50%, 13px);
        left: 50%;
    }
}
.desktop-navbar{
    .dropdown-toggle {
        border: none;
        box-shadow: none;
    }
    button{
        &::after {
            border: none !important;
        }
    }
    .dropdown-menu {
        background-color: $Linen;
        right: 0;
        left: auto;
        padding-top: 18px;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 991px) {
    .desktop-navbar{
        .half-drop-down{
            .dropdown-menu{
                right: 0;
                left: 0;
                top: 100%;
            }
            button {
                &::after {
                    filter: invert(1);
                }
            }
        }
    }
}