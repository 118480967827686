@import "../../assets/scss/variable.scss";

.body-content {
    height: calc(100vh - 321px);
    .left-scroll-bar {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        min-width: 140px;
        scrollbar-width: none;
        display: flex;
        .left-color {
            width: 86px;
            text-align: center;
            padding: 10px 6px 0;
            .color-round {
                height: 52px;
                width: 52px;
                &::before {
                    height: 79px;
                    width: 79px;
                    border-radius: 50%;
                    content: "";
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    border: 1px solid $LavenderGrey;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                }
                .color-name{
                    display: none;
                    position: relative;
                    transform: translate(100%, 0%);
                    left: 15px;
                    text-align: left;
                    transition: 0.5s;
                }
                &.active {
                    width: 65px;
                    height: 65px;
                    &::before{
                        opacity: 1;
                    }
                    .color-name {
                        display: block;
                    }
                }
                &:hover{
                    .color-name{
                        display: block;
                        transition: 0.5s;
                    }
                }
            }
        }
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    .center-image {
        img {
            height: calc(100vh - 280px);
            max-height: 511px;
            transition: all 0.4s linear;
        }
        .canvas-part {
            .canvas-part-dot {
                span {
                    min-width: 11px;
                    min-height: 11px;
                    background-color: #D9D9D9;
                    width: 11px;
                    height: 11px;
                    display: inline-block;
                    margin: 0 23px 0 0;
                    border-radius: 50%;
                    &.active {
                        background-color: $Green;
                    }
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }
    .center-home {
        height: calc(100vh - 289px);
        width: 100%;
        margin-left: -100px;
      }
      .animate{transition: all .2s ease-in;}
      .page-title{
          color: #ffd100;
      }
      .img-hover-shadow {box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25)}
      .main-section{background-color: #FBF8F4;}
    .right-pattern {
        width: 86px;
        text-align: center;
        .pattern-round {
            width: 71px;
            height: 71px;
            margin-bottom: 37px;
            &::before {
                height: 86px;
                width: 86px;
                border-radius: 50%;
                content: "";
                position: absolute;
                z-index: 1;
                top: 50%;
                border: 1px solid $LavenderGrey;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
            }
            .pattern-name{
                display: none;
                position: absolute;
                right: 0;
                transform: translate(-100%, -50%);
                top: 50%;
                right: 15px;
                width: 100%;
                text-align: right;
            }
            &.active{ 
                &::before {
                    opacity: 1;
                }
                .pattern-name {
                    display: block;
                }
            }
            &:hover{
                .pattern-name{
                    display: block;
                    transition: 0.5s;
                }
            }
        }
    }
}

.footer-content{
    .slick-slider {
        .slick-prev, .slick-next {
            color: $Black;
            z-index: 9;
        }
        svg {
            height: 23px;
            width: 14px;
        }
    }
    .info-icon {
        font-size: 28px;
    }
    .color-pattern-tab {
        .left-color {
            .color-round{
                height: 40px;
                width: 40px;
                &::before {
                    height: 52px;
                    width: 52px;
                    border-radius: 50%;
                    content: "";
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border: 1px solid $LavenderGrey;
                    opacity: 0;
                }
                .color-name{
                    display: none;
                    position: absolute;
                    right: 0;
                    transform: translate(-50%, 10px);
                    top: 100%;
                    bottom: 0;
                    text-align: center;
                    margin: 0 auto;
                    width: max-content;
                    left: 50%;
                }
                &.active{
                    &::before {
                        opacity: 1;
                    }
                    .color-name{
                        display: block;
                    }
                }
                &:hover{
                    .color-name{
                        display: block;
                    }
                }
            }
        }
        .right-pattern {
            .pattern-round {
                width: 40px;
                height: 40px;
                &::before{
                    height: 52px;
                    width: 52px;
                    border-radius: 50%;
                    content: "";
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border: 1px solid $LavenderGrey;
                    opacity: 0;
                }
                .pattern-name{
                    display: none;
                    position: absolute;
                    right: 0;
                    transform: translate(-50%, 10px);
                    top: 100%;
                    bottom: 0;
                    text-align: center;
                    margin: 0 auto;
                    width: max-content;
                    left: 50%;
                }
                &.active{
                    &::before {
                        opacity: 1;
                    }
                    .pattern-name{
                        display: block;
                    }
                }
                &:hover{
                    .pattern-name{
                        display: block;
                    }
                }
            }
        }
    }
    .nav-item {
        .border-left-radius {
            border-radius: 20px 0 0 20px;
            border: 1px solid $Black;
            color: $Black;
        }
        .border-right-radius {
            border-radius: 0px 20px 20px 0px;
            border: 1px solid $Black;
            color: $Black;
        }
        a.active {
            background-color: $Black;
            color: $white;
        }
    }
    .input-tab {
        .nav-item {
            a{
                border: 1px solid transparent;
                border-radius: 20px;
            }
            a.active {
                border: 1px solid black;
            }
        }
    }
    .slider-list {
        cursor: pointer;
        .slider-list-menu {
            display: none;
            position: absolute;
            width: 100%;
            bottom: 0px;
            border-radius: 4px;
            box-shadow: 0px 4px 4px rgb(0, 0, 0,0.25);
            width: 155px;
            left: 50%;
            transform: translate(-50%, 0px);
            z-index: 9;
            ul {
                li{
                    color: $DarkGrey;
                    transition: 0.5s;
                    &:hover{
                        background-color: $Green;
                        color: $white;
                    }
                    &.active{
                        background-color: $Green;
                        color: $white;
                    }
                }
            }
        }
        &.active{
            .slider-list-menu {
                display: block;
            }
        }
    }
    .footer-all-option {
        .footer-icon {
            cursor: pointer;
            transition: 0.5s;
            svg {
                margin-bottom: 6px;
                path,ellipse {
                    fill: #ACA9A2;
                }
            }
            p {
                display: none;
            }
            &:hover {
                transition: 0.5s;
                svg {
                    margin-bottom: 6px;
                    path,ellipse {
                        fill: $Green;
                    }
                }
                p {
                    transition: 0.5s;
                    color: $Green;
                    display: block;
                }
            }
            &.active {
                transition: 0.5s;
                p {
                    color: $Green;
                    display: block;
                }
                svg {
                    path,ellipse {
                        fill: $Green;
                    }
                }
            }
        }
    }
}

.handcrafted-by-woolly-made-sec {
    .modal-content {
        background-color: $RoseWhite;
        .modal-body {
            .slick-slider {
                .slick-prev, .slick-next{
                    color: $Black;
                    width: 30px;
                    height: 30px;
                }
                .slick-dots {
                    li {
                        button{
                            &::before {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modification-offcanvas {
    height: auto !important;
    .offcanvas-body{
        img {
            border-radius: 15px;
            transition: 0.5s;
            &:hover {
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            }
        }
    }
    .inspiration-content {
        .inspiration-col{
            .save-img {
                height: 256px;
                img{
                    object-fit: cover;
                }
            }
            .inspiration-button{
                align-items: center;
                display: none;
                height: 100%;
                inset: 0;
                justify-content: center;
                position: absolute;
                width: 100%;
                button{
                    border-radius: 50px;
                }
            }
            &:hover{
                .inspiration-button{
                    display: flex;
                    border-radius: 20px;
                    box-shadow: 0 2px 10px 0 rgb(57 57 57 / 10%), 0 7px 72px 0 rgb(53 53 53 / 18%);
                    transition: .5s cubic-bezier(.075,.82,.165,1);
                }
            }
        }
    }
}
.tooltip {
    .tooltip-inner {
        background: none;
        color: $Black;
        font-weight: 500;
    }
    .tooltip-arrow
    {
        &::before{
            display: none;
        }
    }
}
.fade.show.tooltip.bs-tooltip-start,
.fade.show.tooltip.bs-tooltip-end,
.fade.show.tooltip.bs-tooltip-bottom {
    position: absolute;
    top: 0;
    text-align: center;
    transition: 0s;
    left: 0;
}
.global-backdrop{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 1;
    position: fixed;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    // background-color: aqua;
}
.img-box{
    // background-color: rosybrown;
    // width: 200px;
    height: 380px;
    display: flex;
justify-content: center;
}
.img-cover{
    // width: 100%;
    // height: 125%;
    object-fit: cover;
    // margin: -80px 0;
}
.margin-80{
    margin: -80px;
}
@media only screen and (max-width: 991px) {
    .footer-content {
        height: auto;
        .app-drawer {
            button{
                svg{
                    font-size: 40px;
                }
            }
        }
        .color-pattern-hide {
            transition: 0.4s;
            transition-timing-function: ease-out;
            height: 0;
            overflow: hidden;
        }
        .color-pattern-hide.active {
            height: 100%;
            transition: 0.4s;
            transition-timing-function: ease-in;
        }
    }
    .image-body {
        .body-content {
            height: calc(100vh - 216px);
            transition: all 0.4s linear;
        }
        .body-height {
            height: calc(100vh - 475px);
            .center-image{
                img{
                    padding: 50px 0;
                    height: calc(100vh - 450px);
                    transition: all 0.4s linear;
                }
            }
            .center-home{
                height:calc(100vh - 50px);
            }

        }
        .center-home{
            height:calc(100vh - 50px);
            max-width: 990px;
        }
    }
    .img-box{
        .img-cover{
        width: 100%;
        }
        height: 250px;
    }
}
@media only screen and (max-width: 576px) {
    .modification-offcanvas {
        .inspiration-content {
            .inspiration-col {
                .inspiration-button {
                    display: flex;
                }
            }
        }
    }   
}
