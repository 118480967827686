@import "../scss/variable.scss";

@font-face {
    font-family: 'hochelaga';
    src: url('../fonts/Hochelaga-Regular.otf');
}
@font-face {
    font-family: 'avenir';
    src: url('../fonts/AvenirLTStd-Book.otf');
}
@font-face {
    font-family: 'avenir_bold';
    src: url('../fonts/AvenirLTStd-Black.otf');
}

body{
    font-family: 'avenir';
}

.text-avenir_bold{
    font-family: 'avenir_bold';
}
.text-hochelaga{
    font-family: 'hochelaga';
}

.text-dark-grey{
    color: $DarkGrey;
}
.text-Vanilla{
    color: $Vanilla;
}
.text-vistaWhite {
    color: $VistaWhite !important;
}
.text-semi-wider {
    letter-spacing: 0.12em;
}
.font-max-bold {
    font-weight: 900;
}
.font-semi-max-bold {
    font-weight: 800;
}
.bg-green{
    background-color: $Green;
}
.bg-rose-white{
    background-color: $RoseWhite;
}
.lavender-grey-border{
    border-color: $LavenderGrey !important;
}
.text-xs-max {
    font-size: 15px;
}

.btn-fill-green{
    color: $Black;
    background: transparent;
    border-color: $white;
    &:hover {
        color: $white;
        border-color: $Green;
        background-color: $Green;
    }
    &:focus {
        color: $white;
        background-color: $Green;
        border-color: $Green;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 98, 65, 0.5);
    }
}

.btn-green{
    color: $white;
    background: $Green;
    border-color: $Green;
    &:hover {
        color: $Green;
        border-color: $Green;
        background-color: transparent;
    }
    &:focus {
        color: $Green;
        background-color: transparent;
        border-color: $Green;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 98, 65, 0.5);
    }
}

.btn-outline-white{
    color: $white;
    background: transparent;
    border-color: $white;
    &:hover {
        color: $Black;
        border-color: $white;
        background-color: $white;
    }
    &:focus {
        color: $white;
        background-color: transparent;
        border-color: $white;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255 ,255, 255, 0.5);
    }
}
.btn-outline-black{
    color: $Black;
    background: transparent;
    border-color: $Black;
    &:hover {
        color: $white;
        border-color: $Black;
        background-color: $Black;
    }
    &:focus {
        color: $white;
        background-color: $Black;
        border-color: $Black;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255 ,255, 255, 0.5);
    }
}
.btn-outline-midnight-blue{
    color: $MidnightBlue;
    background: transparent;
    border-color: $MidnightBlue;
    &:hover {
        color: $white;
        border-color: $MidnightBlue;
        background-color: $MidnightBlue;
    }
    &:focus {
        color: $white;
        background-color: $MidnightBlue;
        border-color: $MidnightBlue;
        box-shadow: 0 1px 1px rgba(11, 7, 50, 0.075), 0 0 0 3px rgba(11, 7, 50, 0.5);
    }
}

.scroll-hide{
    &::-webkit-scrollbar{
        width: 0px;
    }
}

.btn-transparent{
    background-color: transparent;
}

.bg-transparent{
    background-color: transparent;
}

.bg-linen{
    background-color: $Linen;
}
.bg-vista-white {
    background-color: $VistaWhite;
}
.input-border-bottom{
    border-bottom: 1px solid $Black !important;
}
.black-box-shadow{
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.input-border{
    border: 1px solid $Black;
    &:focus{
        border: 1px solid $Black;
    }
}